<!-- 
<div class="row instalment-row">
    <div class="col-sm-3 instalment-div" *ngFor="let instalment of formControl.value">
        <p [ngClass]="{ 'title-center': instalment.centerTitle }">{{ instalment.title }}</p>
        <p>Amount: {{ instalment.totalAmount }}</p>
    </div>
</div> 
-->
<div *ngIf="!props.disabled; else instalmentSchedules">
    <mat-tab-group
        (selectedTabChange)="tabChanged($event)"
        class="mb-3"
        [selectedIndex]="selectedIndex"
        [className]="'instalmentTab'"
    >
        <mat-tab
            #tab
            *ngFor="let option of field.templateOptions.options; let i = index"
            [label]="option.label"
            [disabled]="!tab.isActive && to.disabled"
            [labelClass]="'data-testid-' + option.type + '-instalment-tab'"
        >
            <ng-container *ngTemplateOutlet="instalmentSchedules"></ng-container>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #instalmentSchedules>
    <div class="card-table">
        <div *ngIf="loading" class="d-flex justify-content-center align-items-center h-50">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div *ngIf="!loading" class="px-1 pb-3">
            <mat-card>
                <mat-card-content>
                    <div>
                        <table mat-table [dataSource]="field.formControl.value" data-testid="instalmentsTable">
                            <ng-container matColumnDef="instalmentIndex">
                                <th mat-header-cell *matHeaderCellDef>Instalment No.</th>
                                <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
                            </ng-container>

                            <ng-container matColumnDef="dueDate">
                                <th mat-header-cell *matHeaderCellDef>Due Date</th>
                                <td mat-cell *matCellDef="let element">
                                    <pa-format-date
                                        [dateConfig]="{date: element.dueDate, timezone: to.timezone}"
                                    ></pa-format-date>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dueAmountString">
                                <th mat-header-cell *matHeaderCellDef>Amount</th>
                                <td mat-cell *matCellDef="let element">{{ element.dueAmountString }}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: displayedColumns"
                                data-testid="instalmentsTable-row"
                            ></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" [attr.colspan]="displayedColumns.length">No records to show</td>
                            </tr>
                        </table>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</ng-template>
