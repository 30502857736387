import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {
    IdfResponse,
    PayResponse,
    PdfAttachmentResponse,
    InvoiceResponse,
    InstalmentResponse,
    InstalmentSchedule,
} from '../service.portal.types';
import { CustomEncoder, prependIdf, prependPay } from '../service.portal.http.config';

import { AuthService } from '../core/authentication/authentication.service';

import { InsuranceServiceModule } from './insurance.service.module';
import { ClientProposal, IncidentReportServiceDetails, ProposedInstalment } from '@pa/sdk/idf';
import { Observable, from, of } from 'rxjs';
import { PaymentOption } from '@pa/references';
import { InstalmentType, TransactionType } from '@pa/references/idf';
import { SdkService } from '../services/sdk.service';

interface QuoteState {
    accepted: boolean;
    paymentOption?: PaymentOption;
}

@Injectable({
    providedIn: InsuranceServiceModule,
})
export class InsuranceService {
    private _quoteState: QuoteState = {
        accepted: false,
    };

    private instalmentSchedules: { [k in string]: InstalmentSchedule[] } = {};

    constructor(
        private _http: HttpClient,
        private _authService: AuthService,
        private _customEncoder: CustomEncoder,
        private sdkService: SdkService
    ) {}

    get quoteState(): QuoteState {
        return this._quoteState;
    }

    actionReferral(action: 'accept' | 'decline', clientProposal: string, behaviourCode: string) {
        let endpoint = '';
        switch (action) {
            case 'accept':
                endpoint = 'referred';
                break;
            case 'decline':
                endpoint = 'declined';
                break;
            default:
                const _check: never = action;
        }

        const uri = prependIdf(`/v1/quotes/${endpoint}`);
        return this._http
            .post<IdfResponse<ClientProposal>>(uri, { clientProposal: clientProposal, behaviour: behaviourCode })
            .pipe(map((res) => res.message));
    }

    submitTermsAcceptance(reqData: any) {
        this._quoteState.accepted = true;
        return this._http
            .post<IdfResponse<any>>(prependIdf(`/v1/quotes/bind`), reqData)
            .pipe(map((res) => res.message));
    }

    submitTermsAcceptanceDirect(invoiceId: string, reqData: any) {
        this._quoteState.accepted = true;
        this._quoteState.paymentOption = reqData.paymentOption;
        return this._http
            .post<PayResponse<any>>(prependPay(`/v1/invoices/${invoiceId}/direct-payment`), reqData)
            .pipe(map((res) => res.message));
    }

    getEditLink(): string {
        const link = [
            `${environment.loginPortalUri}/pp`,
            `?token=${encodeURIComponent(this._authService.session.token)}`,
            `&proposalId=${this._authService.session.clientProposalId}`,
            `&behaviour=${this._authService.session.behaviour}`,
        ].join('');

        return link;
    }

    getInvoice(invoiceId: string, token: string, code: string, behaviour: string, isCredit: boolean): any {
        let params: any = {
            code,
            token,
            behaviour,
        };
        if (isCredit) {
            params.isCredit = true;
        }
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            params: new HttpParams({
                encoder: this._customEncoder,
                fromObject: params,
            }),
        };
        return this._http.get<InvoiceResponse>(prependPay(`/v1/invoices/${invoiceId}`), options);
    }

    getAttachmentPdf(
        invoiceId: string,
        attachmentId: string,
        token: string,
        code: string,
        behaviour: string,
        isCredit: boolean
    ) {
        let params: any = {
            code,
            token,
            behaviour,
        };
        if (isCredit) {
            params.isCredit = true;
        }
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),

            params: new HttpParams({
                encoder: this._customEncoder,
                fromObject: params,
            }),
        };
        return this._http.get<PdfAttachmentResponse>(
            prependPay(`/v1/invoices/${invoiceId}/${attachmentId}/pdf`),
            options
        );
    }

    getIncidentReport(incidentReport: string, token: string): Observable<IncidentReportServiceDetails> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),

            params: new HttpParams({
                encoder: this._customEncoder,
                fromObject: {
                    token,
                },
            }),
        };

        return this._http
            .get<IdfResponse<any>>(prependIdf(`/v1/incidentReports/${incidentReport}/service`), options)
            .pipe(map((res) => res.result as IncidentReportServiceDetails));
    }

    getInstalments(invoiceId: string, token: string, code: string, behaviour: string, paidDate: string) {
        let params: any = {
            code,
            token,
            behaviour,
            paidDate,
        };
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),

            params: new HttpParams({
                encoder: this._customEncoder,
                fromObject: params,
            }),
        };
        return this._http.get<InstalmentResponse>(prependPay(`/v1/invoices/${invoiceId}/instalments`), options);
    }

    submitHoldCover(
        proposalId: string,
        token: string,
        behaviour: string,
        holdCoverExpiryDate: string,
        holdCoverReasons: string
    ) {
        let params: any = {
            token,
            behaviour,
        };
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),

            params: new HttpParams({
                encoder: this._customEncoder,
                fromObject: params,
            }),
        };
        return this._http
            .post<PayResponse<any>>(
                prependIdf(`/v1/quotes/hold/${proposalId}`),
                { holdCoverExpiryDate, reasons: [holdCoverReasons] },
                options
            )
            .pipe(map((res) => res.message));
    }

    getInstalmentSchedules(
        behaviour: string,
        effectiveDate: string,
        instalmentType: InstalmentType,
        token: string,
        transactionType: TransactionType,
        clientProposal: string,
        invoice: string,
        totalDueAmount?: number
    ): Observable<InstalmentSchedule[]> {
        const key = effectiveDate + instalmentType + instalmentType + token + transactionType;
        if (this.instalmentSchedules[key]?.length) {
            return of(this.instalmentSchedules[key]);
        }

        return from(
            this.sdkService.idf.Instalments.getSchedules({
                behaviour,
                effectiveDate,
                instalmentType,
                token,
                totalDueAmount,
                transactionType,
                clientProposal,
                invoice,
            }).catch(() => [])
        ).pipe(
            map((instalments: ProposedInstalment[]) => {
                // Ensure that the amounts are always in accounting format i.e. 2 decimal places
                const instalmentSchedule: InstalmentSchedule[] = instalments?.map((instalment) => ({
                    ...instalment,
                    dueAmountString: instalment.dueAmount?.toFixed(2),
                    dueAmountArray: instalment.dueAmountArray?.map((durAmountObj) => ({
                        ...durAmountObj,
                        amountString: durAmountObj.amount?.toFixed(2),
                    })),
                }));

                // Cache the result
                this.instalmentSchedules[key] = instalmentSchedule;

                return instalmentSchedule;
            })
        );
    }
}

export const listPoints = {
    'pa-theme': 'fal fa-drone-alt fa-lg',
    'ora-theme': 'fal fa-drone-alt fa-lg',
    'hdi-theme': 'fal fa-plane fa-md',
    'hssb-theme': 'fal fa-plane fa-md',
};

export const documentPoints = {
    'pa-theme': 'fal fa-file-alt fa-lg',
    'ora-theme': 'fal fa-file-alt fa-lg',
    'hdi-theme': 'fal fa-file-alt fa-md',
    'hssb-theme': 'fal fa-file-alt fa-md',
    'hscb-theme': 'fal fa-file-alt fa-md',
};
